import React from "react";
import "./Sec3.css";
import sec11img from "../../../assets/Sec11.png";

const Index = () => {
  return (
    <div className="main_sec11_div" id="help">
      <div className="sub_main_div_sec11">
        <div
          className="image_of_sec11"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img
            src={sec11img}
            alt=""
            style={{
              width: "60%",
            }}
          />
        </div>
        <div className="text_of_sec11">
          <div className="inner_div_text_sc11">
            <h1>WE NEED YOUR HELP</h1>
            <p>
              The app that is currently available is our beta version, meaning
              it’s our first attempt. We want you to help us shape this app to
              become a daily tool for tradespeople, so if you have any
              recommendations, please let us know so we can work together to
              protect our tools! You can contact Matthew directly at
              Matt@ToolArchive.co.uk to send any recommendations. Let’s protect
              our community together!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
