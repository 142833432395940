import React from "react";
import "./Sec4.css";
import sec8img from "../../../assets/Sec8.png";

const Index = () => {
  return (
    <div className="main_sec8_div">
      <div className="sub_main_div_sec8">
        <div className="text_of_sec8">
          <div className="inner_div_text_sec8">
            <h1>WHAT TOOLS CAN BE ADDED</h1>
            <p>
              Any tool with a serial number can be added to the Tool Archive
              service, no matter how big or small. From circular saws to
              lawnmowers, carpenters to mechanics, we can all use the Tool
              Archive app to protect our livelihoods.
            </p>
          </div>
        </div>
        <div className="image_of_sec8">
          <img src={sec8img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
