import React from "react";
import "./Home.css";
import Navbar from "../../components/UI/Navbar/index";
import Footer from "../../components/UI/Footer/index";
import Sec1 from "./Section1/index";
import Sec2 from "./Section2/Index";
import Sec3 from "./Section3/index";
import Sec4 from "./Section4/index";
import Sec5 from "./Section5/index";
import Sec6 from "./Section6/index";
import Sec7 from "./Section7/index";
import Sec8 from "./Section8/index";
import Sec9 from "./Section9/index";
import Sec10 from "./Section10/index";
import Sec11 from "./Section11/index";
import Sec12 from "./Section12/index";

const Index = () => {
  return (
    <div className="MAIN_home">
      <Navbar />
      <Sec1 />
      <Sec2 />
      <Sec3 />
      <Sec4 />
      <Sec5 />
      <Sec6 />
      <Sec7 />
      <Sec8 />
      <Sec9 />
      <Sec10 />
      <Sec11 />
      <Sec12 />
      <Footer />
    </div>
  );
};

export default Index;
