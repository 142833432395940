import React from "react";
import "./Sec4.css";
import sec10img from "../../../assets/Sec10.png";

const Index = () => {
  return (
    <div className="main_sec10_div">
      <div className="sub_main_div_sec10">
        <div className="text_of_sec10">
          <div className="inner_div_text_sec10">
            <h1>OUR STORY</h1>
            <p>
              The Tool Archive story begins with Matthew, a self-employed
              carpenter who began working on construction sites at the age of
              12. Throughout his career, he has witnessed the impact of tool
              theft on tradespeople and wanted to find a solution. The idea for
              Tool Archive first came to him in 2017 and stayed with him until
              he started development in 2023, finally releasing the app in 2024.
            </p>
          </div>
        </div>
        <div
          className="image_of_sec10"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          <img src={sec10img} alt="" style={{ width: "80%" }} />
        </div>
      </div>
    </div>
  );
};

export default Index;
