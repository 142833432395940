import React from "react";
import "./Sec4.css";
import sec12img from "../../../assets/Sec12.png";

const Index = () => {
  return (
    <div className="main_sec3_div" id="Contact">
      <div className="sub_main_div_sec3">
        <div className="text_of_sec12">
          <div className="div_of_contactUs">
            <h1> Contact Us</h1>
            <a href="mailto:Info@ToolArchive.co.uk">
              Help: Info@ToolArchive.co.uk
            </a>
            <a href="mailto:Matt@ToolArchive.co.uk">
              Matt: Matt@ToolArchive.co.uk
            </a>
            <a href="mailto:Press@ToolArchive.co.uk">
              Press: Press@ToolArchive.co.uk
            </a>
          </div>
        </div>
        <div
          className="image_of_sec3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
          <img src={sec12img} alt="" style={{ width: "60%" }} />
        </div>
      </div>
    </div>
  );
};

export default Index;
