import React from "react";
import "./fotter.css";
import Gplay from "../../../assets/playestore.png";
import AStore from "../../../assets/appstore.png";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";

const index = () => {
  const insta = (
    <svg
      width="42 "
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0178 7.71582C30.6181 7.71582 29.5684 8.76796 29.5684 10.1708C29.5684 11.5736 30.6181 12.6258 32.0178 12.6258C33.4175 12.6258 34.4673 11.5736 34.4673 10.1708C34.4673 8.76796 33.4175 7.71582 32.0178 7.71582Z"
        fill="white"
      />
      <path
        d="M20.9966 10.8716C15.2228 10.8716 10.6738 15.6062 10.6738 21.2176C10.6738 26.8289 15.3978 31.5636 20.9966 31.5636C26.5954 31.5636 31.3194 26.8289 31.3194 21.2176C31.3194 15.6062 26.7704 10.8716 20.9966 10.8716ZM20.9966 27.8811C17.3224 27.8811 14.348 24.9 14.348 21.2176C14.348 17.5351 17.3224 14.5541 20.9966 14.5541C24.6708 14.5541 27.6452 17.5351 27.6452 21.2176C27.6452 24.9 24.6708 27.8811 20.9966 27.8811Z"
        fill="white"
      />
      <path
        d="M29.3937 0H12.7723C5.5988 0 0 5.61138 0 12.6256V29.2844C0 36.2986 5.5988 41.91 12.5973 41.91H29.2187C36.2172 41.91 41.816 36.2986 41.816 29.2844V12.6256C41.991 5.61138 36.3922 0 29.3937 0ZM37.9668 29.4597C37.9668 34.1944 34.1177 38.2275 29.2187 38.2275H12.5973C7.87331 38.2275 3.84917 34.3697 3.84917 29.4597V12.801C3.84917 8.06636 7.69834 4.03318 12.5973 4.03318H29.2187C33.9427 4.03318 37.9668 7.891 37.9668 12.801V29.4597Z"
        fill="white"
      />
    </svg>
  );
  const fb = (
    <svg
      width="20"
      height="40"
      viewBox="0 0 20 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6426 40V22H19.1074L20 14H13.6426V10.1035C13.6426 8.04352 13.6952 6 16.5738 6H19.4894V0.280273C19.4894 0.194273 16.985 0 14.4514 0C9.15999 0 5.8468 3.31439 5.8468 9.40039V14H0V22H5.8468V40H13.6426Z"
        fill="white"
      />
    </svg>
  );
  const Twitter = (
    <svg
      width="35"
      height="35"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0L15.4013 22.4527L0.326204 39.9159H3.66621L16.8764 24.6028L27.3806 39.9159H40L23.9093 16.46L38.1084 0H34.7787L22.4322 14.3037L12.6194 0H0ZM3.98831 2.10084H11.5136L36.0117 37.815H28.4864L3.98831 2.10084Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div className="Main_Footer_div">
      <div className="sub_main_div_of_Footer">
        <div className="main_store_img_div_footer">
          <div className="img_store">
            <img src={Gplay} alt="" />
          </div>
          <div className="img_store">
            <img src={AStore} alt="" />
          </div>
        </div>
        <div className="div_oficones">
          <a href="http://https://www.instagram.com/"> {insta}</a>
          <a href="http://https://www.facebook.com/">{fb}</a>
          <a href="http://https://x.com/?lang=en">{Twitter}</a>
        </div>
        <div className="div_polcys_footer">
          <Link className="link_of_footer">Terms and Conditions</Link>
          <Link className="link_of_footer">Privacy Policy</Link>
          <Link className="link_of_footer">
            Marketing and Promotional Policy
          </Link>
        </div>
        <div className="footer_data_of_text">
          <div className="text_div_of_footer">
            <p>
              Tool App Tool Archive Ltd <br /> Registration Number: 15269691{" "}
              <br />
              <br />
              17-19 Dillwyn Street, Swansea, SA1 4AQ
            </p>
          </div>
          <div className="logo_div_footer">
            <div className="logo_img_f">
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
