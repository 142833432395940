import React from "react";
import "./Sec1.css";
import img1 from "../../../assets/Sec1_1.png";
import img2 from "../../../assets/Sec1_2.png";
const Index = () => {
  return (
    <div className="main_sec1_div" id="Sec1">
      <div className="submain_div">
        <div className="img_div_main">
          <div className="phone_img">
            <img src={img1} alt="" />
          </div>
          <div className="phone_img">
            <img src={img2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
