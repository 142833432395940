import React from "react";
import "./Sec4.css";
import sec6img from "../../../assets/Sec6.png";

const Index = () => {
  return (
    <div className="main_sec6_div">
      <div className="sub_main_div_sec6">
        <div className="text_of_sec6">
          <div className="inner_div_text_sc6">
            <h1>FOUND A STOLEN TOOL</h1>
            <p>
              If you discover a stolen tool, it is vital to start the process of
              returning it to its owner. Remember, your safety is the most
              important priority so do not engage in any conflict with the
              seller. Instead,﻿ you can message the owner within the Tool
              Archive app to explain where you have found their tool. It is also
              important to contact the police to inform them that you have found
              a stolen tool. They will be able to trace the tool back to the
              owner through their access to the Tool Archive database.
            </p>
          </div>
        </div>
        <div className="image_of_sec6">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
