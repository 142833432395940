import React from "react";
import "./Sec3.css";
import sec7img from "../../../assets/Sec7.png";

const Index = () => {
  return (
    <div className="main_sec7_div">
      <div className="sub_main_div_sec7">
        <div className="image_of_sec7">
          <img src={sec7img} alt="" />
        </div>
        <div className="text_of_sec7">
          <div className="inner_div_text_sc7">
            <h1>EVERY POLICE FORCE</h1>
            <p>
              Tool Archive and the team aim to get the right tool into the right
              person's hands, including the police. We strive to give every
              police force access to tackle tool theft more effectively and help
              return stolen tools to their rightful owners.
              <br />
              <br />
              For more information, email :{" "}
              <a href="mailto:Info@toolarchive.co.uk">Info@toolarchive.co.uk</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
