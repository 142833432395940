import React from "react";
import "./Sec3.css";
import sec3img from "../../../assets/Sec3.png";

const Index = () => {
  return (
    <div className="main_sec3_div" id="Sec3">
      <div className="sub_main_div_sec3">
        <div className="image_of_sec3">
          <img src={sec3img} alt="" />
        </div>
        <div className="text_of_sec3">
          <div className="inner_div_text_sc3">
            <h1>REGISTERING YOUR TOOL</h1>
            <p>
              Registering your tool with Tool Archive is quick and simple. Just
              open the app, enter your tool’s details, and snap a photo. Proving
              ownership is the first step in creating a safer space for our
              tools. With every registration, you’re strengthening the
              community’s ability to track and recover stolen tools. Secure your
              assets with ease – start registering your tools today!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
