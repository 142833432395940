import React from "react";
import "./Sec3.css";
import sec9img from "../../../assets/Sec9.png";

const Index = () => {
  return (
    <div className="main_sec9_div">
      <div className="sub_main_div_sec9">
        <div className="image_of_sec9">
          <img src={sec9img} alt="" />
        </div>
        <div className="text_of_sec9">
          <div className="inner_div_text_sec9">
            <h1>ITS OUR COMMUNITY</h1>
            <p>
              As tradespeople, we need to look out for each other and Tool
              Archive is here to help us do that. We deserve better than how
              we've been treated and we’re here to change that. Remember to
              check and report stolen tools so that together we can help prevent
              tool theft.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
