import React from "react";
import "./Sec2.css";
import Gplay from "../../../assets/playestore.png";
import Astore from "../../../assets/appstore.png";

const Index = () => {
  return (
    <div className="main_div_section2" id="">
      <div className="sub_main_div_sec2">
        <div className="main_store_img_div">
          <div className="img_store">
            <img src={Gplay} alt="" />
          </div>
          <div className="img_store">
            <img src={Astore} alt="" />
          </div>
        </div>
        <div className="main_text_sec2">
          <h1>TOOL ARCHIVE</h1>
          <p>
            Introducing Tool Archive, the app built by the trade community for
            the trade community. Easily register your tools to prove ownership,
            and if they ever get stolen, mark them with a digital red flag in
            the app. Fellow users can check serial numbers to see if a tool is
            reported stolen making it easier to trace and recover your valuable
            equipment and prevent the purchase of stolen goods. Let’s safeguard
            our power tools and protect our livelihoods together with Tool
            Archive.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Index;
