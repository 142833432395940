import React from "react";
import "./Sec3.css";
import sec5img from "../../../assets/Sec5.png";

const Index = () => {
  return (
    <div className="main_sec5_div">
      <div className="sub_main_div_sec5">
        <div className="image_of_sec5">
          <img src={sec5img} alt="" />
        </div>
        <div className="text_of_sec5">
          <div className="inner_div_text_sc5">
            <h1>CHECKING A TOOL</h1>
            <p>
              Checking if a tool is stolen is easy with the Tool Archive app.
              Simply search the tool's serial number in the Tool Checker
              feature. Make sure to enter the correct serial number for accurate
              results. If you’re unsure where to find the serial number on a
              tool, you can usually locate this information by searching the
              brand online. Be careful not to confuse the serial number with the
              model number.
              <br />
              <br />
              For help, email :{" "}
              <a href="mailto:Info@toolarchive.co.uk">Info@toolarchive.co.uk</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
