import { useState, useEffect, useRef } from "react";
import "./Nav.css";
import logo from "../../../assets/logo.png";
import logo2 from "../../../assets/logo.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setlang] = useState();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const toggle = (item) => {
    // console.log("navbar item clicked", item);
    setIsopen(false);
    // setshow(false);
  };
  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);

  // const Langchngae = (e) => {
  //   const Link = e.target.value;
  //   Navigate(`${Link}`);
  // };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <Link to="Sec1" className="logo">
            <img src={logo} alt="" />
          </Link>
          <Link to="Sec1" className="logo2">
            <img src={logo2} alt="" />
          </Link>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <Link
                to="Sec1"
                offset={-102}
                spy={true}
                smooth={true}
                onClick={() => toggle("")}>
                Home
              </Link>
            </li>
            <li onMouseMove={() => setonshow(true)}>
              <Link
                to="Sec3"
                offset={-100}
                spy={true}
                smooth={true}
                onClick={() => toggle("")}>
                Registering
              </Link>
            </li>
            <li>
              <Link
                to="help"
                offset={-100}
                spy={true}
                smooth={true}
                onClick={() => toggle("")}>
                Help
              </Link>
            </li>

            <li>
              <Link
                to="Contact"
                offset={-100}
                spy={true}
                smooth={true}
                onClick={() => toggle("")}>
                Contact
              </Link>
            </li>

            <button className="download_btn">
              <Link to="Sec8" offset={-100}>
                Download App
              </Link>
            </button>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
        {/* <Outlet /> */}
      </div>
    </div>
  );
};

export default Navbar;
// path name :-  "/"          language name :-English
// path name :-  "/Turkish"   language name :-Turkish
// path name :-   "/French"   language name :-French
// path name :-  "/Portuguese" language name :-Portuguese
// path name :-  "/Spanish"   language name :-Spanish
// path name :-  "/Swahili"   language name :-Swahili
