import React from "react";
import "./Sec4.css";
import sec4img from "../../../assets/Sec4.png";

const Index = () => {
  return (
    <div className="main_sec4_div">
      <div className="sub_main_div_sec4">
        <div className="text_of_sec4">
          <div className="inner_div_text_sec4">
            <h1>REPORTING A TOOL AS STOLEN</h1>
            <p>
              Reporting your tool as stolen is simple and easy with the Tool
              Archive app. Just click 'Report Stolen' within the app, and your
              tool will be flagged immediately. This helps the community stay
              informed and increases the chances of recovering your valuable
              equipment. It is vital that you also contact the police to create
              a crime number for the theft.
            </p>
          </div>
        </div>
        <div className="image_of_sec4">
          <img src={sec4img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
